.Input__Input__s67N0 {
  width: 100% !important;
}
.Spinner__Spinner__1fiyk {
  width: 100px;
  height: 100px;
  margin: 0 auto !important;
  display: block !important;
}

.Login__Auth__1Y4B7 {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
}
.Login__AuthFormContainer__1wzyN{
  display: table;
  width: 100%;
  height: 100%; 
}

.Login__AuthFormSubContainer__2_kKp{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.Login__AuthForm__qMcTu {
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  background: white;
  padding: 40px;
  text-align: center;
}

.Login__SubmitButton__1OOIX {
  padding: 10px 40px !important;
  margin-top: 40px !important;
  color: #fff !important;
}

.Login__ErrorMessage__24Zi0 {
  color: red;
}

@media (min-width: 960px) {
  .Login__AuthForm__qMcTu {
    max-width: 40%;
  }
}

.Login__Logo__3d1gV {
  max-width: 350px;
  margin: 32px;
}
.Pagination__PaginationContainer__35pck {
  width: 100%;
}

.Pagination__Pagination__37gIt .Pagination__PageItem__2jtuC {
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  vertical-align: top;
  height: 30px;
}

.Pagination__Pagination__37gIt .Pagination__PageLink__KUOpr {
  color: #444;
  cursor: pointer;
  padding: 0 12px;
  line-height: 30px;
  outline: none;
  text-decoration: none;
}

.Pagination__PaginationContainer__35pck {
  text-align: center;
}

.Pagination__Pagination__37gIt {
  text-align: center;
  padding: 0;
  margin-top: 25px;
  width: 100%;
} /*

/* padding: 4px 0;

*/

.Pagination__Active__o4qQS {
  background-color: var(--primary-red);
  color: #fff;
}

.Pagination__Active__o4qQS a {
  color: white !important;
}

.Pagination__Pagination__37gIt li:hover:not(.Pagination__Active__o4qQS) {
  background-color: #ddd;
}

.Pagination__Disabled__3woqZ a {
  cursor: default;
  color: #999;
}

.Pagination__ChevronIcon__3EGoD {
  margin-top: 2px;
}

.Select__Wrapper__2Kqcf {
  display: inline-block;
  width: 100%;
}

.Select__CustomMenu__2Pvst {
  z-index: 4 !important;
}

.Select__CustomSelect__2PJDI {
  border: 1px solid hsl(0, 0%, 80%) !important;
  width: 100%;
  color: var(--primary-red) !important;
  border-radius: 2px !important;
  text-transform: uppercase;
  box-shadow: none !important;
  background: white !important;
  font-size: 16px;
  margin-top: 35px;
}

.Select__CustomSelect__2PJDI:hover {
  border: 1px solid hsl(0, 0%, 80%) !important;
}

.Select__CustomOption__KbotV {
  background-color: transparent !important;
  color: var(--primary-red) !important;
  text-align: left;
  text-transform: uppercase;
}

.Select__CustomOption__KbotV:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
  color: var(--primary-red) !important;
}

.Select__DropdownIcon__7LSK4 {
  color: var(--primary-red);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

@media (min-width: 960px) {
  .Select__CustomSelect__2PJDI {
    font-size: 16px;
  }
}

.Input_TextField__awn4M,
.Input_Textarea__gqu61 {
  width: 90%;
}

.Input_Input__3DjAQ {
  width: 100% !important;
}

.Input_Recaptcha__25DzB {
  width: 98%;
  margin: 0 auto;
  margin-top: 15px;
}

.Input_Dense__2RNM5 {
  margin-top: 16px !important;
}

.Input_Textarea__gqu61 {
  width: 90%;
}

.Input_Checkbox__1nyuO {
  margin-left: 5% !important;
}

.Input_SelectContainer__1DOPq {
  width: 80%;
}

.Input_Invalid__1TB8X {
  border: 1px solid red;
  background-color: salmon;
}

.Input_ValidationError__31DfU {
  color: red;
  margin: 5px 0;
}

.Input_OutlinedSelect__2Z3z4 {
  width: 100% !important;
}

.Input_ReactDatePicker__UyeG5 > div {
  display: block !important;
}

.Input_ReactDatePicker__UyeG5 > div > div {
  width: 100%;
}

.Input_Dense__2RNM5 {
  margin-top: 16px !important;
  z-index: 0;
}

.Input_FullWidth__-LbCe {
  width: 100% !important;
}

.Input_InputPlaceholder__5mgGO::-webkit-input-placeholder {
  color: black;
  outline: 0;
  opacity: 1 !important;
}

.Input_InputPlaceholder__5mgGO::placeholder {
  color: black;
  outline: 0;
  opacity: 1 !important;
}

.Input_Label__3Mj-l {
  color: rgba(0, 0, 0, 1);
  padding: 0 0 0.9rem 0;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;
}

.Input_DatePickerMainDiv__1-w9T > div {
  display: block !important;
}

.Input_DatePickerMainDiv__1-w9T > div > div {
  width: 100%;
}

@media (min-width: 960px) {
  .Input_TextField__awn4M {
    width: 80%;
  }

  .Input_Recaptcha__25DzB {
    width: 80%;
  }

  .Input_SmallInput__lKosb {
    height: 25px !important;
    padding: 7px;
    font-size: 12px;
  }

  .Input_Label__3Mj-l {
    padding-bottom: 0.5rem;
  }
}

@media (min-width: 1600px) {
  .Input_SmallInput__lKosb {
    height: 19px !important;
    padding: 15px 14px;
    font-size: 1rem;
  }

  .Input_Label__3Mj-l {
    padding-bottom: 0.8rem;
  }
}

.OutlinedSelect_FormControl__1RFFB {
  text-align: left !important;
  width: 100% !important;
  height: 50px !important;
}

.CreateBranchOffice__FileInput__rtEKI {
  margin: 1rem 0;
  font-size: 1rem;
}

.CreateBranchOffice__SubmitButton__3rRDN {
  margin: 0 auto 25px auto !important;
}

.PromotionsDetail__CardBody__21cOt {
  padding: 50px !important;
}

.PromotionsDetail__Title__2Jg_R {
  padding-bottom: 16px;
}

.EditableTitleSection__EditButton__3I-aq {
  padding: 2px !important;
  color: #fffffff0 !important;
  margin-left: 20px !important;
}

.EditableTitleSection__SectionDescriptionTitle__H7DgK {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
}

.EditableTitleSection__SectionDescription__2zrJT {
  margin-bottom: 40px;
  margin-top: 20px;
}

.EditableTitleSection__SectionDescription__2zrJT p {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.TextEditorDialog__TextEditor__3bBlC h1 {
  text-align: center;
}

.TextEditorDialog__CustomDialog__PYTKw {
  max-width: 80% !important;
}

.TextEditorDialog__Toolbar__1NL1K {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
  background: #cccccc80;
}

div.DraftEditor-root {
  background-color: #ffffff;
  margin:auto;
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
  height:300px;
  overflow-y: scroll;
  padding:20px;
  font-size: 16px;
  font-family: 'calibri', sans-serif;
}
.RseBannersDetail__CardBody__3GbH2 {
  padding: 50px !important;
}

.RseBannersDetail__Title__2rE6N {
  padding-bottom: 16px;
}

.TextEditor__TextEditor__38ed6 {
  text-align: left;
}

.TextEditor__TextEditor__38ed6 h1 {
  text-align: center;
}

.TextEditor__CustomDialog__2kRzX {
  max-width: 80% !important;
}

.TextEditor__Toolbar__16q7B {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  background: #cccccc80;
}

div.DraftEditor-root {
  background-color: #ffffff;
  margin: auto;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  height: 300px;
  overflow-y: scroll;
  padding: 20px;
  font-size: 16px;
  font-family: 'calibri', sans-serif;
}

.Settings__LoadingContainer__2Hkru {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Settings__EditorContainer__129Bh {
  margin: 1rem 0 !important;
  width: 80%;
}

.Settings__SubmitButton__18pWl {
  margin: 1rem auto !important;
}

.Settings__TabsContainer__3yVjm {
  background-color: #fff;
  padding-bottom: 1rem;
}

.Settings__TabContent__2XgPU {
  text-align: center;
  width: 100%;
}

.CreateDiscounts__FileInput__2vnsc {
  margin: 1rem 0;
  font-size: 1rem;
}

.CreateDiscounts__SubmitButton__1evEn {
  margin: 0 auto 25px auto !important;
}

.AgilePurchases__ButtonIcon__KTrmw {
  margin-right: 0.5rem;
}

.AgilePurchases__Button__3fKlM span {
  font-size: 1rem;
  color: #fff;
  display: flex;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
}

.AgilePurchases__Button__3fKlM {
  margin-left: 1rem;
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
}

.AgilePurchases__Button__3fKlM:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.CreateAgilePurchases__FileInput__KhpJZ {
  margin: 1rem 0;
  font-size: 1rem;
}

.CreateAgilePurchases__SubmitButton__3HYYD {
  margin: 0 auto 25px auto !important;
}

.CreateFaq__SubmitButton__1cNpE {
  margin: 0 auto 25px auto !important;
}
.CharacteristicsForm__H2Image__1BSHS {
  margin: 2rem 0 0 0 !important;
}

.CharacteristicsForm__Image__1WyP0 {
  width: 350px !important;
}

.CharacteristicsForm__ImageInput__VMRZH {
  font-size: 1rem !important;
  margin: 1rem 0 !important;
}

.CharacteristicsForm__SubmitButton__2i5DD {
  margin: 0 auto 25px auto !important;
}

.CharacteristicsForm__FormControl__20Ebr {
  width: 100%;
  text-align: left;
  display: flex;
}

.CharacteristicsDetail__CardBody__2le91 {
  padding: 50px !important;
}

.CharacteristicsDetail__Title__211b4 {
  padding-bottom: 16px;
}

.CharacteristicsDetail__ImgStyle__17QKd {
  width: 350px !important;
}
.BankImageForm__H2Image__3Z7KI {
  margin: 2rem 0 0 0 !important;
}

.BankImageForm__Image__3NXcD {
  width: 350px !important;
}

.BankImageForm__ImageInput__idXoR {
  font-size: 1rem !important;
  margin: 1rem 0 !important;
}

.BankImageForm__SubmitButton__3qIS2{
    margin: 0 auto 25px auto !important;
}
.BankImageDetail__CardBody__1L6Wc {
  padding: 50px !important;
}

.BankImageDetail__Title__ZMIkj {
  padding-bottom: 16px;
}

.BankImageDetail__ImgStyle__h_RP1 {
  width: 350px !important;
}

.BankImageDetail__InformationDiv__14OLC {
  display: flex;
}

.SideDrawer__Active__MFS5P {
  background: linear-gradient(to bottom, #ec1f39f2 0%, #ec1f39ff 100%);
  box-shadow: 0 12px 20px -10px rgba(215, 3, 12, 0.28),
    0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(215, 3, 12, 0.2);
  transition: all 100ms linear;
  border-radius: 3px;
  width: 90%;
  margin: 0 auto;
}

:root {
  --primary-red: #ec1f39;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #eee;
}

.Feedback {
  margin-top: 12%;
  text-align: center;
  font-size: 24px;
}    
  

